import { Box, ConditionsModal, LinkDocument } from '@dltru/dfa-ui'
import { FC } from 'react'

import { IAgreementModal } from './types'

export const RiskNotification: FC<IAgreementModal> = ({ ...props }) => {
    return (
        <ConditionsModal
            title={<Box align="center">Уведомление о рисках</Box>}
            {...props}
            text={
                <>
                    <h3>
                        <b>Общие положения</b>
                    </h3>
                    <p>
                        Цель настоящего уведомления о рисках (далее – Декларация) – предоставить Вам
                        информацию об основных рисках, связанных с совершением сделок с ЦФА и иных
                        действий в ИС, и предупредить о возможных потерях, которые могут возникнуть
                        в связи с реализацией указанных рисков.
                    </p>
                    <p>
                        Обращаем Ваше внимание на то, что Декларация не раскрывает информацию обо
                        всех рисках, связанных с совершением сделок с ЦФА и иных действий в ИС,
                        вследствие разнообразия возникающих ситуаций, и не является рекомендацией
                        Оператора по инвестиционным, налоговым и юридическим вопросам.
                    </p>
                    <p>
                        Присоединяясь к Правилам, Вы подтверждаете свою осведомленность в отношении
                        рисков, описываемых в Декларации, а также выражаете своё согласие на их
                        принятие.
                    </p>
                    <p>
                        Все термины, встречающиеся в тексте Декларации с заглавной буквы и
                        содержание которых не раскрыто в Декларации, применяются в значении,
                        предусмотренном Правилами информационной системы ООО «Системы
                        распределенного реестра», расположенными по адресу <LinkDocument />.
                    </p>

                    <h3>
                        <b>Виды рисков</b>
                    </h3>
                    <p>
                        В Декларации под риском понимается возможность возникновения ситуации,
                        которая может повлечь для Пользователя:
                        <ol>
                            <li>потерю части или всех денежных средств; и (или)</li>
                            <li>потерю стоимости части или всех ЦФА; и (или)</li>
                            <li>
                                изменения прав и обязанностей Пользователя в худшую для него
                                сторону.
                            </li>
                        </ol>
                    </p>
                    <p>
                        <b>I. Кредитный риск</b>&emsp;–&emsp;риск, связанный с неисполнением
                        (ненадлежащим исполнением) или невозможностью исполнения обязательств (далее
                        – Неисполнение обязательств), принятых на себя другими лицами перед
                        Пользователем, включая, но не ограничиваясь:
                        <ol>
                            <li>
                                Риск неисполнения обязательств Эмитентом (дефолт) – риск
                                Неисполнения обязательств, принятых на себя Эмитентом в соответствии
                                с Решением о выпуске ЦФА. В частности, такой риск может проявиться
                                вследствие возбуждения дела о несостоятельности (банкротстве)
                                Эмитента и (или) введения в отношении Эмитента определенных
                                ограничительных мер;
                            </li>
                            <li>
                                Риск неисполнения обязательств Оператором – риск Неисполнения
                                обязательств, принятых на себя Оператором в соответствии с
                                Правилами, в том числе, вследствие сбоя в работе информационных
                                технологий и технических средств ИС;
                            </li>
                            <li>
                                Риск неисполнения обязательств кредитной организацией – риск
                                Неисполнения обязательств, принятых на себя кредитной организацией в
                                рамках договора номинального счета, заключенного с Оператором, по
                                которому Пользователь является одним из бенефициаров.
                            </li>
                        </ol>
                        Порог вхождения на рынок ЦФА ниже, чем на рынки иных финансовых
                        инструментов, что может влиять на величину кредитного риска.
                    </p>

                    <p>
                        <b>II. Операционный риск</b>&emsp;–&emsp;риск, реализующийся вследствие:
                        <ol>
                            <li>
                                сбоев в работе программного и аппаратного обеспечения, сетей связи,
                                энергоснабжения Пользователя, а также сбоев в работе технических
                                средств Оператора и его партнеров;
                            </li>
                            <li>
                                совершения третьим лицом от имени Пользователя сделок в ИС в
                                результате получения таким лицом случайным образом или в результате
                                его преднамеренных действий несанкционированного доступа к ЛК
                                Пользователя;
                            </li>
                            <li>
                                проведения Пользователем в ЛК Пользователя сделок, несоответствующих
                                его намерениям, по причинам, связанным с недостаточным опытом работы
                                Пользователя в ЛК Пользователя, и (или) в результате случайных
                                действий такого Пользователя;
                            </li>
                            <li>
                                невозможности направления Оператору волеизъявлений и иных юридически
                                значимых сообщений по причинам технического характера.
                            </li>
                        </ol>
                    </p>

                    <p>
                        <b>III. Отраслевой риск</b>&emsp;–&emsp;риск, вызванный неблагоприятным
                        функционированием определенной отрасли, в которой Эмитент осуществляет
                        основную финансово-хозяйственную деятельность, включая, но не ограничиваясь:{' '}
                        <br />
                        <ol>
                            <li>
                                риски, связанные с возможным изменением цен на основные виды сырья,
                                товаров, работ, услуг, используемых Эмитентом в своей деятельности
                                (отдельно на рынке Российской Федерации и рынках за пределами
                                Российской Федерации), их влияние на деятельность Эмитента и
                                исполнение обязательств;
                            </li>
                            <li>
                                риски, связанные с возможным изменением цен на товары, работы и
                                (или) услуги Эмитента (отдельно на рынке Российской Федерации и
                                рынках за пределами Российской Федерации), и их влияние на
                                деятельность Эмитента и исполнение обязательств.
                            </li>
                        </ol>
                        Отраслевой риск непосредственно сказывается на деятельности занятых в ней
                        Эмитентов, а значит, и на показателях стоимости ЦФА, выпущенных такими
                        Эмитентам. Часто негативное состояние одной из отраслей передается компаниям
                        из других зависимых отраслей.
                    </p>
                    <p>
                        <b>IV. Правовой риск</b>&emsp;–&emsp;риск, связанный с применением
                        действующего на территории Российской Федерации законодательства, в том
                        числе налогового законодательства Российской Федерации, принятием,
                        изменением или отменой существующих правовых актов Российской Федерации,
                        субъектов Российской Федерации и органов местного самоуправления и
                        отсутствием правовых актов, регулирующих деятельность на рынке ЦФА. Следует
                        иметь в виду, что ЦФА в большей, нежели иные финансовые инструменты, степени
                        подвержены правовому риску, поскольку правовое регулирование рынка ЦФА
                        находится в стадии формирования.
                    </p>
                    <p>
                        <b>V. Рыночный риск</b>&emsp;–&emsp;риск возникновения финансовых потерь
                        (убытков) вследствие изменения текущей (справедливой) стоимости
                        принадлежащих Пользователю ЦФА.
                    </p>
                    <p>
                        <b>VI. Риск ликвидности</b>&emsp;–&emsp;риск снижения возможности быстро
                        передать принадлежащие Пользователю ЦФА за эквивалентное встречное
                        предоставление (денежные средства, вещи или имущественные права).
                    </p>
                    <p>
                        <b>VII. Риск недостижения инвестиционных целей</b>&emsp;–&emsp;риск,
                        возникающий в связи с недостижением Пользователем своих инвестиционных
                        целей. Оператор не предоставляет гарантии того, что сохранение и (или)
                        увеличение капитала, которого Пользователь хочет добиться, будет достигнуто.
                        Пользователь должен осознавать, что он может потерять часть или весь
                        капитал, вложенный в ЦФА. Пользователь должен понимать, что он
                        самостоятельно определяет условия сделок с ЦФА, наилучшим образом отвечающих
                        его целям и задачам, и самостоятельно несет ответственность за свой выбор.
                    </p>
                    <p>
                        <b>VIII. Страновой риск</b>&emsp;–&emsp;риск того, что действия органов
                        публичной власти окажут влияние на доходы (в том числе потенциальные),
                        расходы, права и обязанности Пользователя, включая, но не ограничиваясь:
                        <ol>
                            <li>
                                <b>Политический риск</b>&emsp;–&emsp;риск, связанный с изменением
                                политической ситуации в Российской Федерации (изменение политической
                                системы, смена органов власти, изменение политического и (или)
                                экономического курса). Наиболее радикальные изменения могут привести
                                национализации имущества определенных категорий Пользователей;
                            </li>
                            <li>
                                <b>Экономический риск</b>&emsp;–&emsp;риск, связанный с изменением
                                экономической ситуации в Российской Федерации. Любой участник рынка
                                ЦФА в Российской Федерации может оказаться в ситуации, когда в силу
                                экономических причин он не сможет надлежащим образом исполнить свои
                                обязательства, удостоверенные ЦФА или связанные с обращением ЦФА, и
                                иные обязательства, которые могут повлиять на права Пользователя.
                            </li>
                        </ol>
                    </p>
                    <p>
                        <b>IX. Системный риск</b>&emsp;–&emsp;риск реализации события, оказывающего
                        негативное влияние на весь финансовый рынок как отдельной страны, так и
                        группы стран в связи с невозможностью одного или нескольких финансовых
                        институтов выполнить свои функции. В настоящее время страны, финансовые
                        организации и институты имеют высокую степень взаимосвязанности и
                        взаимодействия, что обусловлено процессами глобализации и универсализации, а
                        также высокой скоростью передачи данных и информации. Оценка системного
                        риска является сложной задачей, но при этом реализация указанного риска
                        может значительно повлиять на всех участников финансового рынка.
                    </p>
                </>
            }
        />
    )
}
